import React from "react"

const FacebookSvg = (props) => (
  <svg viewBox="16 12 11 23" {...props}>
    <path d="M23.3147057,35 L18.4372418,35 L18.4372418,23.4985483 L16,23.4985483 L16,19.535534 L18.4372418,19.535534 L18.4372418,17.1562737 C18.4372418,13.9234411 19.8127074,12 23.7230238,12 L26.9776468,12 L26.9776468,15.964466 L24.9435074,15.964466 C23.4212558,15.964466 23.3206665,16.5182719 23.3206665,17.5518493 L23.3139606,19.535534 L27,19.535534 L26.5685836,23.4985483 L23.3139606,23.4985483 L23.3139606,35 L23.3147057,35 Z"></path>
  </svg>
)

const InstagramSvg = (props) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
  </svg>
)

const TwitterSvg = (props) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
  </svg>
)

const LinkedinSvg = (props) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M19 0H5a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5ZM8 19H5V8h3v11ZM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764ZM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19Z" />
  </svg>
)

const SparkleSiteSvg = (props) => (
  <svg {...props} viewBox="0 0 128 128">
    <path d="m88.55 39.995-17.658 21.07c2.337.26 7.53.52 12.724.52 11.166 0 24.928-.78 32.719-5.462 20.254-12.226 9.088-38.76-13.763-31.736 7.271-22.892-19.475-34.078-31.42-13.788-4.674 7.804-4.934 21.071-4.934 32.257 0 5.983.26 11.706.26 14.047L87.77 39.214c.519-.78 1.298 0 .779.78ZM38.433 87.339 56.61 66.528c-2.078 0-8.05-.26-14.023-.26-11.166 0-24.409.52-32.199 4.942-19.995 11.967-8.829 38.761 14.022 31.477-7.27 22.892 19.476 34.077 31.68 13.787 4.674-7.804 5.453-21.591 5.453-32.777 0-5.463-.519-10.666-.519-13.007L39.471 87.86c-.779.78-1.558 0-1.038-.52ZM117.115 71.21c-7.791-4.682-21.034-4.942-32.2-4.942-5.713 0-11.425.26-13.763.26l17.918 20.81c.52.521-.26 1.562-1.039 1.041L66.478 70.95c-.26 2.341-.519 7.544-.519 13.267 0 11.186.78 24.973 5.453 32.777 12.205 20.291 38.691 9.105 31.68-13.787 22.851 6.764 34.277-20.03 14.023-31.997ZM40.256 40.255l21.033 16.388c0-2.341.26-8.064.26-13.787 0-11.186-.52-24.453-4.934-32.257-12.204-20.29-38.95-9.104-31.68 13.788-22.851-7.284-34.017 19.51-13.762 31.736 7.79 4.682 21.552 5.463 32.718 5.463 5.453 0 10.647-.52 12.984-.52l-17.398-19.77c-.78-.781 0-1.562.779-1.041Z" />
  </svg>
)

export const IconSocials = ({ name, ...props }) => {
  const formattedName = name.toLowerCase()
  switch (formattedName) {
    case "facebook":
      return <FacebookSvg {...props} />
    case "instagram":
      return <InstagramSvg {...props} />
    case "twitter":
      return <TwitterSvg {...props} />
    case "linkedin":
      return <LinkedinSvg {...props} />
    case "sparklesite":
      return <SparkleSiteSvg {...props} />
    default:
      return
  }
}

export default IconSocials
