import React from "react"
import styled from "styled-components"
import { ButtonPrimary } from "../homepage/Buttons"

import "../homepage/layout.css"

import AboutHero from "../about/AboutHero"
import AboutBeliefs from "../about/AboutBeliefs"
import LeadershipTeam from "../about/LeadershipTeam"
import RateToDonate from "../about/RateToDonate"
import PressCallout from "./PressCallout"

const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
const Section = styled.section`
  :before,
  :after {
    content: "";
    clear: both;
    display: table;
  }
  line-height: 1.3;
  font-size: 1.25rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  h1 {
    font-size: 4.5rem;
    line-height: 0.95;
    margin-bottom: 0.5em;
  }
  @media screen and (max-width: 900px) {
    font-size: 1rem;
    line-height: 1.4;
    h1 {
      font-size: 2.25rem;
      line-height: 0.95;
      margin-bottom: 0.5em;
    }
  }
  ${({ dark }) => {
    if (dark)
      return `
        background: var(--sparkle-darkblue);
        color: #fff;
    `
  }}
`
const Content = styled.div`
  padding: 8rem var(--content-pad);
  max-width: 900px;
  margin: auto;

  h2 {
    text-align: center;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem var(--content-pad);
  }
`
const BackBtn = styled.button`
  font-size: 0rem;
  font-weight: bold;
  position: absolute;
  width: 45px;
  height: 45px;
  background: #fff;
  opacity: 0.75;
  border-radius: 100%;
  left: var(--content-pad);
  top: var(--content-pad);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Page = () => {
  return (
    <Section dark>
      <Content>
        <div id="about-hero">
        <AboutHero />
        </div>
        <AboutGrid>
        <Container href="#about-beliefs">
            <h4 style={{
                  color: "white",
                  paddingBottom: "0.5rem",
                }}>We Sparkle Values</h4>
                                <p
            style={{
                marginTop: "0.5rem",
            }}
            >
            Get to know us through our mission, vision, and values.
            </p>
              <ButtonPrimary
                style={{
                  padding: "1rem",
                  fontSize: "0.85rem",
                  fontWeight: 400,
                }}
              >
                Learn More
              </ButtonPrimary>
          </Container>
          <Container href="#about-team">
            <h4 style={{
                  color: "white",
                  paddingBottom: "0.5rem",
                }}>We Sparkle Team</h4>
                                <p
            style={{
                marginTop: "0.5rem",
            }}
            >
            Get to know our team members.
            </p>
              <ButtonPrimary
                style={{
                  padding: "1rem",
                  fontSize: "0.85rem",
                  fontWeight: 400,
                }}
              >
                Learn More
              </ButtonPrimary>
          </Container>
          <Container href="#about-impact">
            <h4 style={{
                  color: "white",
                  paddingBottom: "0.5rem",
                }}>We Sparkle Gives Back</h4>

            <p
            style={{
                marginTop: "0.5rem",
            }}
            >
            Check out our social-impact initiatives.
            </p>
              <ButtonPrimary
                style={{
                  padding: "1rem",
                  fontSize: "0.85rem",
                  fontWeight: 400,
                }}
              >
                Read More
              </ButtonPrimary>
          </Container>
          <Container href="/press">
            <h4 style={{
                  color: "white",
                  paddingBottom: "0.5rem",
                }}>We Sparkle In the News</h4>
                <p
            style={{
                marginTop: "0.5rem",
            }}
            >
            Check us out in the news.
            </p>
              <ButtonPrimary
                style={{
                  padding: "1rem",
                  fontSize: "0.85rem",
                  fontWeight: 400,
                }}
              >
                Go To Press 
              </ButtonPrimary>
          </Container>
        </AboutGrid>
        <div id="about-beliefs">
        <AboutBeliefs />
        </div>
        <div id="about-team">
        <LeadershipTeam />
        </div>
        <div id="about-impact">
        <RateToDonate />
        </div>
      </Content>
    </Section>
  )
}

export default Page

const Container = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid white;
  border-radius: 10px;
  padding: 1rem;
  transition: 0.2s;

  p {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    line-height: 1.5;
    color: white;
    overflow: hidden;
    max-height: 10rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;

    &.label {
      color: var(--sparkle-red);
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
  &:hover {
    transform: translateY(-2%);
  }

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 600px) {
    margin: 0;

    a {
      margin-top: 1rem;
    }
  }
`