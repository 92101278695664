import React from "react"
import Seo from "../core/Seo"
import AboutLayout from "../about/Page"

const AboutPage = () => {
  return (
    <>
      <Seo
        title="We Sparkle • Who We Are and What We Value | Our Story"
        includeZohoTracking
      />
      <AboutLayout />
    </>
  )
}

export default AboutPage
