import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Container = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 4rem 0 6rem;
    gap: 3rem;

    &::before {
        position: absolute;
        content: '“';
        font-size: 8rem;
        color: var(--sparkle-red);
        font-weight: bold;
        top: 0;
        line-height: 0.5;
        @media screen and (max-width: 900px) {
        font-size: 6rem;
        line-height: 0.75;
        }
    }

    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-auto
        -rows: min-content;
    }
`
const Content = styled.div`

    h1.hero-quote {
        font-size: 2.8rem;
        line-height: 1.3;
        margin-bottom: 2rem;

        span {
            color: var(--sparkle-red)
        }
        @media screen and (max-width: 900px) {
        font-size: 2rem;
        }
    }
    p {
        font-size: 1rem;
        line-height: 1.4;
    }

`
const FeaturedImage = styled.div`
    position: relative;
    @media screen and (max-width: 900px) {
        max-width: 350px;
        margin: 0 auto;
    }
`
const NameCallout = styled.div`
    position: absolute;
    width: 175px;
    height: 60px;
    border-radius: 50px;
    bottom: -10%;
    right: 0;

    &::before {
        position: absolute;
        content: '“';
        font-size: 8rem;
        color: white;
        font-weight: bold;
        top: 0;
        right: 0;
        line-height: 0;
        @media screen and (max-width: 900px) {
        font-size: 6rem;
        }
    }
    p {
        color: white;
        font-size: 1rem;
        text-align: right;

        &.headshot-name {
            margin: 0.5rem 0 0.25rem;
        }
    }
`
const AboutHero = () => {
    return (
        <div>
            <Container>
                <Content>
                    <h1 className="hero-quote">Let your light shine, help others to shine, so together <span>we sparkle</span>.</h1>
                    <p><strong>We Sparkle's</strong> vision is to build a stronger and more inclusive economy. We accomplish this through our mission to equip small businesses with the AI-powered tools they need to succeed.</p>
                </Content>
                <FeaturedImage>
                    <StaticImage
                        placeholder={"none"}
                        src="./imgs/michelle-tran-maryns_headshot.png"
                        className={"img"}
                        alt={"Michelle Headshot"}
                    />
                    <NameCallout>
                        <p className="headshot-name"><strong>Michelle Maryns</strong></p>
                        <p>Founder & CEO</p>
                    </NameCallout>
                </FeaturedImage>
            </Container>
        </div>
    )
}

export default AboutHero