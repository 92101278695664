import React from "react"
import styled from "styled-components"
import { segmentAction } from "../hooks/useSegmentTrack"

const Container = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  transition: 0.2s;

  @media screen and (max-width: 900px) {
    .gatsby-image-wrapper img {
      width: auto !important;
      height: 100% !important;
      max-height: 200px;
      margin: 0 auto;
    }
  }
  p {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    color: var(--sparkle-darkblue);
  }
  &:hover {
    transform: translateY(-3%);
  }
`
const RecipientCards = ({ recipients }) => {
  return (
    <>
      {recipients.map((item, i) => {
        return (
          <Container
            href={item.url}
            target="_blank"
            rel={"noopener noreferrer"}
            onClick={() => {
              segmentAction.track(`Rate to Donate Recipient Click`, {
                value: item.name,
              })
            }}
            key={i}
          >
            {item.img}
            <p>{item.description}</p>
          </Container>
        )
      })}
    </>
  )
}

export default RecipientCards
