import React from "react"

const AccessibilitySvg = (props) => (
  <svg viewBox="0 0 38 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.095 6.944h31.31a1.39 1.39 0 0 1 1.337 1.012l1.727 6.1.031.218a.802.802 0 0 1-.516.75l-.001.001a.802.802 0 0 1-.992-.36l-1.788-3.237-.512 12.449A1.17 1.17 0 0 1 32.52 25h-.002a1.174 1.174 0 0 1-1.166-1.09c-.106-1.352-.389-4.583-.475-5.863-.034-.531-.423-.844-.845-.844-.378 0-.803.313-.84.844-.085 1.28-.368 4.51-.474 5.862A1.173 1.173 0 0 1 27.553 25h-.001a1.17 1.17 0 0 1-1.17-1.123c-.135-3.25-.68-13.65-.68-13.65h-2.608s-.546 10.4-.68 13.65A1.172 1.172 0 0 1 21.244 25h-.002a1.173 1.173 0 0 1-1.165-1.09c-.107-1.352-.39-4.583-.475-5.863-.036-.531-.461-.844-.84-.844h-.006c-.378 0-.804.313-.839.844-.086 1.28-.369 4.51-.475 5.862A1.174 1.174 0 0 1 16.277 25h-.002a1.17 1.17 0 0 1-1.17-1.123c-.135-3.25-.68-13.65-.68-13.65h-2.627s-.545 10.4-.68 13.65A1.17 1.17 0 0 1 9.949 25h-.001a1.173 1.173 0 0 1-1.166-1.09c-.106-1.352-.389-4.583-.475-5.863-.036-.531-.46-.844-.839-.844-.422 0-.81.313-.845.844-.086 1.28-.369 4.51-.475 5.862A1.174 1.174 0 0 1 4.98 25H4.98a1.17 1.17 0 0 1-1.17-1.123l-.513-12.449-1.788 3.236a.802.802 0 0 1-.992.361l-.001-.002a.802.802 0 0 1-.516-.75l.031-.217 1.727-6.1a1.39 1.39 0 0 1 1.337-1.012ZM7.813 0a3.126 3.126 0 0 1 0 6.25 3.126 3.126 0 0 1 0-6.25Zm21.875 0a3.126 3.126 0 0 0 0 6.25 3.126 3.126 0 0 0 0-6.25ZM18.758 0a3.126 3.126 0 0 1 0 6.25 3.126 3.126 0 0 1 0-6.25Z"
    />
  </svg>
)

const SustainabilitySvg = (props) => (
  <svg viewBox="0 0 29 25" {...props}>
    <path d="M14.063 6.25a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25ZM28.125 18.75v-3.125c-3.5 0-6.5-1.5-8.75-4.187l-2.094-2.5a3.093 3.093 0 0 0-2.39-1.125H13.25c-.922 0-1.797.406-2.39 1.125l-2.094 2.5C6.5 14.125 3.5 15.625 0 15.625v3.125c4.328 0 8.11-1.828 10.938-5.078v3.516l-6.063 2.421a2.808 2.808 0 0 0-1.75 2.594A2.794 2.794 0 0 0 5.922 25h3.453v-.781a3.908 3.908 0 0 1 3.906-3.906h4.688c.437 0 .781.343.781.78a.774.774 0 0 1-.781.782H13.28a2.34 2.34 0 0 0-2.344 2.344V25h11.266A2.794 2.794 0 0 0 25 22.203c0-1.14-.703-2.172-1.75-2.594l-6.063-2.421v-3.516c2.829 3.25 6.61 5.078 10.938 5.078Z" />
  </svg>
)

const CommunitySvg = (props) => (
  <svg viewBox="0 0 39 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.246.387c-2.277 1.181-2.547 3.055-.811 5.66.932 1.4 5.422 6.102 5.826 6.102.396 0 4.861-4.589 5.822-5.984.418-.608.889-1.58 1.044-2.161.249-.922.226-1.175-.173-1.986C33.05.182 30.194-.618 28.681.54c-.38.291-.834.724-1.01.963-.302.407-.386.375-1.372-.529-.922-.845-1.19-.962-2.193-.961-.628 0-1.465.169-1.86.373Zm-16.2 11.607C2.797 12.93.078 13.758.004 13.832c-.113.115 2.11 8.01 2.311 8.21.037.037 1.794-.421 3.904-1.018l3.837-1.086 3.733 2.289C17.675 24.608 18.457 25 19.325 25c.285 0 4.345-1.08 9.02-2.398 7.374-2.08 8.597-2.49 9.215-3.093 1.345-1.311.711-3.684-1.095-4.095-.64-.145-1.657.058-4.76.952l-3.944 1.138-.17 1c-.23 1.35-.567 1.914-1.471 2.462-1.313.796-2.402.615-5.04-.833-2.736-1.503-2.644-1.433-2.47-1.886.106-.273.715-.035 2.797 1.094 2.592 1.404 2.687 1.435 3.604 1.162 1.575-.47 2.229-2.52 1.124-3.526-.296-.27-3.08-1.896-6.185-3.612-5.186-2.865-5.742-3.118-6.822-3.096-.685.013-3.643.734-7.082 1.725Z"
    />
  </svg>
)

const CollaborationSvg = (props) => (
  <svg viewBox="0 0 28 25" {...props}>
    <path d="M6.703 22.727h4.874c0 1.25-1.097 2.273-2.437 2.273-1.34 0-2.437-1.023-2.437-2.273Zm-2.438-1.136h9.75v-2.273h-9.75v2.273ZM18.28 10.795c0 4.341-3.242 6.66-4.595 7.387h-9.09C3.241 17.455 0 15.136 0 10.796 0 6.09 4.095 2.273 9.14 2.273c5.045 0 9.14 3.818 9.14 8.523Zm5.934-2.42-1.67.716 1.67.716.768 1.557.768-1.557 1.67-.716-1.67-.716-.768-1.557-.768 1.557Zm-2.888-1.557 1.146-2.34 2.51-1.069-2.51-1.068L21.326 0l-1.145 2.34-2.51 1.07 2.51 1.067 1.145 2.341Z" />
  </svg>
)

export const IconBeliefs = ({ name, ...props }) => {
  const formattedName = name.toLowerCase()
  switch (formattedName) {
    case "accessibility":
      return <AccessibilitySvg {...props} />
    case "sustainability":
      return <SustainabilitySvg {...props} />
    case "community":
      return <CommunitySvg {...props} />
    case "collaboration":
      return <CollaborationSvg {...props} />
    default:
      return <AccessibilitySvg {...props} />
  }
}

export default IconBeliefs
