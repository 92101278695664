import React from "react"

const IconSparkle = (props) => {
  return (
    <svg viewBox="0 0 22 27" {...props}>
      <path d="M21.6734 13.7618C21.5606 14.0812 21.3846 14.2888 21.1204 14.4139C21.0169 14.463 19.5407 15.0119 17.8399 15.6338C16.139 16.2557 14.652 16.8267 14.5354 16.9026C14.3355 17.0328 14.1786 17.2405 14.0676 17.5223C14.0407 17.5906 13.6651 19.3858 13.233 21.5117C12.3109 26.0476 12.3905 25.6907 12.2554 25.8971C11.7821 26.6202 10.7789 26.5269 10.4185 25.7262C10.3693 25.6168 9.88259 23.7903 9.33702 21.6674C8.79145 19.5445 8.30941 17.7165 8.26583 17.6051C8.17482 17.3725 7.89183 17.0576 7.68559 16.9593C7.60796 16.9223 6.12453 16.4491 4.38908 15.9077C2.65364 15.3662 1.16987 14.9004 1.09185 14.8724C0.847855 14.785 0.548252 14.4339 0.438149 14.1065C0.319478 13.7536 0.31151 13.6018 0.392217 13.2293C0.458709 12.9224 0.633096 12.6301 0.850488 12.4611C0.926579 12.402 2.42333 11.8284 4.17659 11.1863C5.92986 10.5444 7.44308 9.97443 7.53932 9.91989C7.7613 9.79399 8.00407 9.44985 8.0888 9.14107C8.1249 9.0094 8.50028 7.18006 8.92293 5.07588C9.47194 2.34265 9.72412 1.1803 9.80606 1.00572C9.97777 0.639919 10.2552 0.442808 10.6531 0.403985C10.8829 0.381587 11.0208 0.395808 11.1406 0.454264C11.3466 0.554921 11.6129 0.820234 11.7003 1.01183C11.7363 1.09083 12.2244 2.94059 12.7848 5.12235C13.5106 7.94792 13.8379 9.14888 13.9225 9.29649C14.0804 9.57219 14.2442 9.73338 14.4619 9.82755C14.5607 9.87025 16.0916 10.3575 17.864 10.9104C20.0592 11.5951 21.1394 11.9558 21.2526 12.0419C21.7148 12.3935 21.8956 13.1328 21.6734 13.7618Z" />
    </svg>
  )
}

export default IconSparkle
