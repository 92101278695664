import React from "react"
import styled from "styled-components"
import { segmentAction } from "../hooks/useSegmentTrack"
import PressList from "../press/PressList"

const Container = styled.a`
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  border-radius: 10px;
  padding: 1.5rem;
  transition: 0.2s;

  p {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    line-height: 1.5;
    color: white;

    &.label {
      color: var(--sparkle-red);
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
  &:hover {
    transform: translateY(-3%);
  }

  @media screen and (max-width: 600px) {
    margin: 0;

    a {
      margin-top: 1rem;
    }
  }
`
const FeaturedPressCard = () => {
  return (
    <>
      {PressList.slice(0, 3).map((item, i) => {
        return (
          <Container
            href={item.url}
            target="_blank"
            rel={"noopener noreferrer"}
            onClick={() => {
              segmentAction.track(`Press Item Click`, {
                value: item.headline,
              })
            }}
            key={i}
          >
            <p className="label">{item.publication}</p>
            <p>
              <strong>{item.headline}</strong>
            </p>
            <p>{item.description}</p>
          </Container>
        )
      })}
    </>
  )
}

export default FeaturedPressCard
